import { formatCurrency } from '@hapstack/common'
import { Form, useForm } from '@hapstack/remix'
import {
  Button,
  Icon,
  Spinner,
  Switch,
  ToggleGroup,
  ToggleGroupItem,
} from '@hapstack/ui'
import { useFetcher } from '@remix-run/react'
import { useEffect, useState } from 'react'

import { createCheckoutSessionSchema } from './formSchemas'
import type { loader } from './route'
import { INTENTS, type Product } from './types'

export const ProductList = () => {
  const [products, setProducts] = useState<Product[]>([])
  const [selectedProduct, setSelectedProduct] = useState<Product | null>(null)
  const fetcher = useFetcher<typeof loader>()
  const [term, setTerm] = useState<'month' | 'year'>('month')
  const [returnUrl, setReturnUrl] = useState('')

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setReturnUrl(window.location.href)
    }
  }, [])

  useEffect(() => {
    fetcher.load('/resources/billing')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (fetcher.data) {
      setProducts(fetcher.data)
    }
  }, [fetcher.data])

  if (products.length === 0) {
    return (
      <div className="flex h-full min-h-72 w-full items-center justify-center">
        <Spinner size="2xl" />
      </div>
    )
  }

  return (
    <div className="space-y-4">
      <ToggleGroup
        type="single"
        value={selectedProduct?.planKey}
        onValueChange={(value) => {
          setSelectedProduct(products.find((p) => p.planKey === value) || null)
        }}
        className="flex flex-col gap-2 border-none"
      >
        {products.map((product) => {
          return (
            <ToggleGroupItem
              key={product.planKey}
              value={product.planKey}
              className="rounded-lg border-2 border-border p-3 font-normal text-primary first:rounded-l-lg last:rounded-r-lg data-[state=on]:border-accent data-[state=on]:bg-inherit"
            >
              <div className="flex w-full items-center justify-between">
                <div className="flex items-center gap-3">
                  <Icon
                    name="check"
                    size="md"
                    className="invisible text-accent group-data-[state=on]:visible"
                  />
                  <div className="text-left">
                    <div className="font-bold">{product.name}</div>
                    <div className="text-muted-foreground">
                      Companies up to {product.maxEmployees} employees
                    </div>
                  </div>
                </div>

                <div>
                  <span className="font-medium">
                    {formatCurrency(
                      term === 'year'
                        ? product.annualPrice
                        : product.monthlyPrice,
                      { decimalPoints: 0 }
                    )}
                  </span>{' '}
                  <span className="text-muted-foreground">
                    / {term === 'year' ? 'yr.' : 'mo.'}
                  </span>
                </div>
              </div>
            </ToggleGroupItem>
          )
        })}
      </ToggleGroup>

      <div className="flex justify-between gap-16 rounded-lg border-2 border-dashed border-border p-4 text-sm">
        <div>
          <div className="font-medium">Get 2 free months</div>
          <div className="text-pretty text-muted-foreground">
            Choose annual billing and enjoy 2 free months on your plan.
          </div>
        </div>
        <Switch
          checked={term === 'year'}
          onCheckedChange={(checked) => {
            setTerm(checked ? 'year' : 'month')
          }}
        />
      </div>

      <SelectPlanButton
        priceId={
          term === 'year'
            ? selectedProduct?.annualPriceId
            : selectedProduct?.monthlyPriceId
        }
        returnUrl={returnUrl}
      />
    </div>
  )
}

const SelectPlanButton = ({
  priceId,
  returnUrl,
}: {
  priceId?: string
  returnUrl: string
}) => {
  const form = useForm({
    intent: INTENTS.Enum.createCheckoutSession,
    schema: createCheckoutSessionSchema,
    mode: 'onChange',
    useFetcher: true,
    fetcherKey: `${INTENTS.Enum.createCheckoutSession}-${priceId}`,
    submitConfig: { action: '/resources/billing' },
  })

  const { setValue } = form

  useEffect(() => {
    if (!priceId) return
    setValue('priceId', priceId)
  }, [setValue, priceId])

  return (
    <Form {...form}>
      <input
        {...form.register('priceId', { value: priceId })}
        type="hidden"
      />
      <input
        {...form.register('returnUrl', { value: returnUrl })}
        type="hidden"
      />

      <Button
        variant="dark"
        icon="rocket"
        className="w-full"
        // disabled={!form.formState.isValid}
        onClick={form.handleSubmit}
        loading={form.formState.isSubmitting}
      >
        Upgrade
      </Button>
    </Form>
  )
}
