import { useForm } from '@hapstack/remix'
import { Button } from '@hapstack/ui'
import { type ComponentProps, useEffect } from 'react'
import type { z } from 'zod'

import { createPortalSessionSchema } from './formSchemas'
import { INTENTS } from './types'

type PortalSessionProps = z.infer<typeof createPortalSessionSchema>

type Props = Omit<PortalSessionProps, 'returnUrl'> &
  ComponentProps<typeof Button> & {
    fetcherKey?: string
  }

export const CreatePortalSessionButton = ({
  customerId,
  subscriptionId,
  flow,
  fetcherKey,
  children,
  ...props
}: Props) => {
  const form = useForm({
    intent: INTENTS.Enum.createPortalSession,
    schema: createPortalSessionSchema,
    defaultValues: {
      flow: flow || null,
      customerId,
      subscriptionId,
      returnUrl: '',
    },
    submitConfig: { action: '/resources/billing' },
    useFetcher: true,
    fetcherKey,
  })

  const { setValue } = form

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setValue('returnUrl', window.location.href)
    }
  }, [setValue])

  return (
    <Button
      onClick={form.handleSubmit}
      loading={form.formState.isSubmitting}
      {...props}
    >
      {children}
    </Button>
  )
}
