import { z } from 'zod'

export const createPortalSessionSchema = z.object({
  customerId: z.string(),
  subscriptionId: z.string().nullish(),
  flow: z.enum(['payment_method_update']).nullish(),
  returnUrl: z.string(),
})

export const createCheckoutSessionSchema = z.object({
  priceId: z.string().min(1, 'Please select a plan'),
  returnUrl: z.string().url().nullish(),
})
